@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_BOPIS_TO_QUOTE_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_USE_CVM_TEST_DATA($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ONE_REACH_MOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_PRICE_DEVIATION_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_HISTORICAL_ORDER_EDIT_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DTCC_INLINE_APPLY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_EVENTSTREAM__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_HISTORICAL_ORDERS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_RATES_AND_RULES_INFO__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_HISTORY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_RESERVATION_DETAILS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_DRIVER_TYPES_UPDATE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DEBUG_CEC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LEGACY_ORDER_ACTIONS_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_RETURN_ALL_BLOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ALLOWABLE_REFUND__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../shared/scss/_dt-base.scss';

%container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 -20px;

  @include media($min-md) {
    margin: 0 -40px;
  }

  @include media($min-xl) {
    margin: 0;
  }
}

.container {
  @extend %container;
}

.container-w-divider {
  @extend %container;
  border-top: 10px solid $light-gray;
}

.heading {
  margin: 25px 0 10px;
  padding: 5px 0 7px;
  width: calc(100% - 40px);
  max-width: 1600px;
  color: $black;
  text-transform: capitalize;
  border-bottom: 1px solid $grey-200;

  @include media($min-sm) {
    margin-top: 34px;
  }

  @include media($min-md) {
    margin: 34px 0 18px;
    width: calc(100% - 80px);
  }
}

.intro {
  margin: 0 auto 20px 20px;

  @include media($min-md) {
    margin: 0 auto 25px 40px;
  }
}

.content {
  width: calc(100% - 40px);
  max-width: 1600px;

  @include media($min-md) {
    width: calc(100% - 80px);
  }
}

.content-fullwidth {
  width: 100%;
}
