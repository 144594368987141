@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_BOPIS_TO_QUOTE_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_USE_CVM_TEST_DATA($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ONE_REACH_MOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_PRICE_DEVIATION_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_HISTORICAL_ORDER_EDIT_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DTCC_INLINE_APPLY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_EVENTSTREAM__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_HISTORICAL_ORDERS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_RATES_AND_RULES_INFO__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_HISTORY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_RESERVATION_DETAILS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_DRIVER_TYPES_UPDATE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DEBUG_CEC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LEGACY_ORDER_ACTIONS_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_RETURN_ALL_BLOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ALLOWABLE_REFUND__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../../../../../shared/scss/dt-base.scss';

.description {
  color: $medium-gray;
  font-size: rem(12);
}

.nav {
  display: flex;
  position: relative;
  z-index: 1;
}

.tab {
  border: 1px solid $medium-light-gray;
  border-radius: 3px;
  color: $dark-gray;
  flex: 0 1 80px;
  font-size: rem(14);
  font-weight: 700;
  height: 34px;
  line-height: rem(17);
  margin-right: 2px;
}

.tab-selected,
.tab-muted {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tab-selected {
  @extend .tab;
  background-color: $light-gray;
  border-bottom: none;
  border-color: $dark-gray;
  font-weight: 900;
}

.tab-muted {
  @extend .tab;
  border-bottom: 1px solid transparent;
  color: $medium-light-gray;
}

.container {
  background-color: $light-gray;
  border: 1px solid $dark-gray;
  padding: 16px 20px 18px 20px;
  position: relative;
  top: -1px;
  z-index: 0;
}

.collapsible {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid $medium-light-gray;

  &:global(__content) {
    padding: 0px;
  }
}

.description {
  display: flex;
  align-items: center;

  font-size: rem(13);
}

.offset {
  margin: 3px 10px 0 0;
  align-self: flex-start;
}
